import { Box, Button, Card, CardActions, CardContent, Chip, IconButton, Link, Typography, useMediaQuery } from '@mui/material';
import './cardJob.css';
import { Accessibility, Dashboard, LinkedIn, Paid, Place, ShareLocation } from '@mui/icons-material';
import { jobNumberToUrl, separeArray, titleStringJobToUrl } from '../../services/serviceslocal';
import { NavLink, useNavigate } from 'react-router-dom';
import ButtonAction from '../buttonAction/ButtonAction';

export default function CardJob({ item }) {
    const priority = item.Today_s_Priority__c;
    const max400 = useMediaQuery("(max-width:400px)");
    const navigate = useNavigate();

    const urlJob = `../find-a-job/jobs/job?title=${titleStringJobToUrl(item.Title_Website)}&id=${jobNumberToUrl(item.TR1__Job_Number__c)}`;
    const goToJob = () => {
        navigate(urlJob);
    };

    return (
        <Card className={`cardJob col w100`} sx={{ my: 0.5, p: 1, position: 'relative', overflow: 'hidden' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(https://oaidalleapiprodscus.blob.core.windows.net/private/org-1h8YZ5iw0QLAiuWeBtUMXDQO/user-CzROq7oxi1BOS5s5dMAcUPOQ/img-vMG6XiV6E7X6TAID1e6EDygI.png?st=2025-03-31T19%3A13%3A51Z&se=2025-03-31T21%3A13%3A51Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2025-03-31T12%3A36%3A35Z&ske=2025-04-01T12%3A36%3A35Z&sks=b&skv=2024-08-04&sig=F4cBL65eU5IOm3XgL6%2BZGNF8jzpw0gj3zQvMbIaib1c%3D)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    opacity: 0.3, // Adjust opacity here
                    zIndex: 0,
                }}
            />
            <CardContent className="w100" sx={{ position: "relative", zIndex: 1 }}>
                <NavLink to={urlJob} className="cardJob_title flex w100 aicenter">
                    <Typography gutterBottom variant="h5" color="primary" component="h2">
                        {item.Title_Website}
                    </Typography>
                    <Typography noWrap gutterBottom variant="body1" component="span" className="tl">
                        {item.TR1__Job_Number__c}
                    </Typography>
                </NavLink>
                <Box className="flex row w100 wrap">
                    {separeArray([item.TR1__State_Area__c, item.TR1__City__c, item.Country__c])
                        .filter(Boolean)
                        .sort()
                        .map(element => (
                            <Button key={element} sx={{ p: 0, mr: 0.5, my: 0.5, borderRadius: "50px" }} color="secondary">
                                <Chip size="small" icon={<Place />} label={element} />
                            </Button>
                        ))}
                    {item.Type_of_work__c && (
                        <Button sx={{ p: 0, mr: 0.5, my: 0.5, borderRadius: "50px" }} color="secondary">
                            <Chip size="small" icon={<ShareLocation />} label={item.Type_of_work__c} />
                        </Button>
                    )}
                </Box>
            </CardContent>
            <CardActions className="w100" sx={{ position: "relative", zIndex: 1 }}>
                {item.LinkedIn_Post_URL__c && (
                    <Link href={item.LinkedIn_Post_URL__c} underline="none" target="_blank">
                        <Button size="small" color='linkedin' variant="text" sx={{ color: "var(--linkedin)", borderRadius: "50px" }} endIcon={<LinkedIn />}>
                            <Typography sx={{ fontSize: "13px", textTransform: "none" }} noWrap component="span">
                                Apply on
                            </Typography>
                        </Button>
                    </Link>
                )}
                <CardActions sx={{ marginLeft: "auto" }}>
                    <NavLink to={urlJob}>
                        <Button variant='text' style={{ textTransform: "none", borderRadius: "50px" }}>
                            View Job
                        </Button>
                    </NavLink>
                    <ButtonAction
                        variant='contained'
                        sx={{ textTransform: "none", borderRadius: "50px" }}
                        titleJob={item.Title_Website}
                        numberJob={item.TR1__Job_Number__c}
                        idJob={item.Id}
                        odQ1={item.Opportunity_Discussed_Question_1__c}
                        odQ2={item.Opportunity_Discussed_Question_2__c}
                        odQ3={item.Opportunity_Discussed_Question_3__c}
                        action="open:form-talent"
                        translate="no"
                    >
                        Apply ASAP
                    </ButtonAction>
                </CardActions>
            </CardActions>
        </Card>
    );
}
