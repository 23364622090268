import { useState } from 'react';
import { Box, Button } from '@mui/material';
import ModalMessage from '../modalMessage/ModalMessage';
import ModalSendResume from '../modalSendResume/ModalSendResume';
import './buttonAction.css'
import ModalMap from '../modalMap/ModalMap';
import { useNavigate } from 'react-router-dom';
import ModalNewsletters from '../modalNewsletters/ModalNewsletters';
import { eventAnalytics } from '../../services/firebaseConfig';


export default function ButtonAction(props) {


    //assing and remove props
    const {children, action, onClicked, titleJob, idJob, numberJob,odQ1,odQ2,odQ3, ...newProps} = props;

    const navigate = useNavigate()
    const [modalActive, setModalActive] = useState(false)

    const handleClick = () => {
        if(onClicked){
            onClicked(true)
        }
        eventAnalytics(action)

        if(action.split(":")[0] === "open"){
            setModalActive(action.split(":")[1])
            return
        }
        if(action.split(":")[0] === "call"){
            let phone = action.replace("call:","");
            window.location = `tel:${phone}`
            return
        }
        if(action.split(":")[0] === "mail"){
            let mail = action.replace("mail:","");
            window.location = `mailto:${mail}`
            return
        }
        if(action.substr(0,4) === "http"){
            window.open(action, '_blank');
            return
        }
        if(action.substr(0,3) === "www"){
            window.open(`https://${action}`, '_blank');
            return
        }
        if(action.substr(0,1) === "/"){
            navigate(action)
            return
        }

    } 

     return (
        <Box className="contents">
            <Button {...newProps} onClick={() =>handleClick()}>
                {children}
            </Button>

            {modalActive === "form-newsletters" && <ModalNewsletters modalActive={true} setModalActive={setModalActive}/>}
            {modalActive === "form-client" && <ModalMessage modalActive={true} setModalActive={setModalActive}/>}
            {modalActive === "form-talent" && <ModalSendResume odQ1={odQ1} odQ2={odQ2} odQ3={odQ3} numberJob={numberJob} titleJob={titleJob} idJob={idJob} modalActive={true} setModalActive={setModalActive}/>}
            {modalActive === "map-location" && <ModalMap modalActive={true} setModalActive={setModalActive}/>}
        </Box>
    );
}

